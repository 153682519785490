import { create } from "zustand";
import {
  CreateUser,
  UpdateUser,
  User,
  UserRole,
  UsersQuery,
  UsersState,
} from "../types/user";
import { useFetcher } from "./fetcher";
import { Paging } from "../types/paging";
import { sortQueryFromOrderByRecord } from "../utils/query";

export const useUsersStore = create<UsersState>(
  (set, get) =>
    ({
      items: [],
      total: null,
      query: null,
      limit: 20,
      search: async (query?: UsersQuery, fetch?: boolean) => {
        set(() => ({ query }));
        if (fetch !== false) {
          await get().fetch(true);
        }
      },
      fetch: async (reset: boolean): Promise<Paging<User>> => {
        try {
          const { items, limit, query } = get();
          const sort = sortQueryFromOrderByRecord(query?.order_by)
          const params: any = query
            ? { ...query, order_by: undefined, sort, limit, offset: reset ? 0 : items.length }
            : { limit, offset: reset ? 0 : items.length };
          const res = await useFetcher
            .getState()
            .omsFetcher.get<Paging<User>>("users", {
              params,
              paramsSerializer: {
                indexes: null,
              },
            });

          set(() => ({
            items: [...(reset ? [] : items), ...(res?.data?.items ?? [])],
            total: res?.data?.total ?? null,
          }));
          return res?.data ?? null;
        } catch (error) {
          throw error;
        }
      },
      get: async (id: number): Promise<User | null> => {
        try {
          const res = await useFetcher
            .getState()
            .omsFetcher.get<User>(`users/${id}`);
          return res?.data ?? null;
        } catch (error) {
          throw error;
        }
      },
      create: async (input: CreateUser): Promise<User | null> => {
        try {
          const res = await useFetcher
            .getState()
            .omsFetcher.post<User>(`users`, input);
          get().clear();
          return res?.data ?? null;
        } catch (error) {
          throw error;
        }
      },
      update: async (id: number, input: UpdateUser): Promise<User | null> => {
        try {
          const res = await useFetcher
            .getState()
            .omsFetcher.patch<User>(`users/${id}`, input);
          if (res?.data) {
            const items = [...get().items];
            const idx = items.findIndex((value) => {
              return value.id === id;
            });
            if (idx >= 0) {
              items[idx] = { ...res.data };
              set(() => ({ items: items }));
            }
          }
          return res.data ?? null;
        } catch (error) {
          throw error;
        }
      },
      delete: async (id: number): Promise<void> => {
        try {
          const res = await useFetcher
            .getState()
            .omsFetcher.delete(`users/${id}`);
          get().clear();
        } catch (error) {
          throw error;
        }
      },
      getUserRoles: async (id: number): Promise<Paging<UserRole> | null> => {
        try {
          const res = await useFetcher
            .getState()
            .omsFetcher.get<Paging<UserRole>>(`users/${id}/roles`);
          return res?.data ?? null;
        } catch (error) {
          throw error;
        }
      },
      setUserRoles: async (id: number, roles: UserRole[]): Promise<void> => {
        try {
          await useFetcher
            .getState()
            .omsFetcher.put(`users/${id}/roles`, {roles});
        } catch (error) {
          throw error;
        }
      },
      clear: () => {
        set(() => ({ items: [], total: null, query: null }));
      },
    } as UsersState)
);
