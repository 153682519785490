import { Box, Container, Typography } from "@mui/material";
import * as React from "react";
import { useAppStore as useOmsAppStore } from "../stores/app";
import { useTranslation } from "react-i18next";
import { Construction } from "@mui/icons-material";

const MaintenanceRedirector = ({ children }: any) => {
  const omsApp = useOmsAppStore();
  const { t } = useTranslation();
  return omsApp.maintenanceRedirect ? (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        bgcolor: "primary.main",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="h4" color="primary.contrastText">
          {t("maintenance_page_title")}
        </Typography>

        <Box sx={{p: 4}}>
          <Typography variant="h4" color="primary.contrastText">
            <Construction sx={{ width: "80px", height: "80px" }} />
          </Typography>
        </Box>
        
        <Typography variant="subtitle1" color="primary.contrastText">
          {t("maintenance_page_desc1")}
        </Typography>
        <Typography variant="subtitle1" color="primary.contrastText">
          {t("maintenance_page_desc2")}
        </Typography>
      </Container>
    </Box>
  ) : (
    children
  );
};

export default MaintenanceRedirector;
