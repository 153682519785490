import * as React from "react";

import { Container, Toolbar } from "@mui/material";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import AppNavBar from "./AppNavBar";
import AppSideMenu from "./AppSideMenu";

import "../styles/global.css";
import { SEO } from "./SEO";

const Layout = ({ children }: any) => {
  return (
    <Container maxWidth="xl" sx={{ height: "100vh" }}>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "100%",
          overflowY: "hidden",
        }}
        id="layout-box"
      >
        <CssBaseline />
        <AppNavBar />
        <AppSideMenu />

        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            overflowY: "hidden",
          }}
        >
          <Toolbar />
          <Box
            component="main"
            id="main-box"
            sx={{ width: "100%", height: "100%", overflowY: "hidden" }}
          >
            {children}
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default Layout;