import {
  DashboardState,
  GetOrderCountByStatus,
  GetOrderCountTimeSeries,
  OrderCountTimeSeries,
  PointSeries,
} from "../types/dashboard";
import { create } from "zustand";
import { useFetcher } from "./fetcher";

export const useDashboardStore = create<DashboardState>(
  (set, get) =>
    ({
      orderCountTimeSeries: null,
      orderCountTimeSeriesQuery: null,
      orderCountByStatus: null,
      orderCountByStatusQuery: null,
      setOrderCountTimeSeriesQuery: async (
        q: GetOrderCountTimeSeries,
        fetch: boolean
      ): Promise<void> => {
        set(() => ({ orderCountTimeSeriesQuery: q }));
        if (fetch !== false) {
          await get().fetchOrderCountTimeSeries(true);
        }
      },
      setOrderCountByStatusQuery: async (
        q: GetOrderCountByStatus,
        fetch: boolean
      ): Promise<void> => {
        set(() => ({ orderCountByStatusQuery: q }));
        if (fetch !== false) {
          await get().fetchOrderCountByStatus(true);
        }
      },
      fetchOrderCountTimeSeries: async (
        forceReload: boolean
      ): Promise<OrderCountTimeSeries | null> => {
        let output = get().orderCountTimeSeries;
        if (!output || forceReload) {
          const { ...params } = get().orderCountTimeSeriesQuery ?? {};
          const res = await useFetcher
            .getState()
            .omsDsFetcher.get<OrderCountTimeSeries>(
              "dashboard/order-count-time-series",
              {
                params,
                paramsSerializer: {
                  indexes: null,
                },
              }
            );
          if (res?.data) {
            set(() => ({ orderCountTimeSeries: res.data }));
            output = res.data;
          } else {
            set(() => ({ orderCountTimeSeries: null }));
            output = null;
          }
        }
        return output;
      },
      fetchOrderCountByStatus: async (
        forceReload: boolean
      ): Promise<PointSeries | null> => {
        let output = get().orderCountByStatus;
        if (!output || forceReload) {
          const { ...params } = get().orderCountByStatusQuery ?? {};
          const res = await useFetcher
            .getState()
            .omsDsFetcher.get<PointSeries>("dashboard/order-count-by-status", {
              params,
              paramsSerializer: {
                indexes: null,
              },
            });
          if (res?.data) {
            set(() => ({ orderCountByStatus: res.data }));
            output = res.data;
          } else {
            set(() => ({ orderCountByStatus: null }));
            output = null;
          }
        }
        return output;
      },
      clear: () => {
        set(() => ({
          orderCountTimeSeries: null,
          orderCountTimeSeriesQuery: null,
          orderCountByStatus: null,
          orderCountByStatusQuery: null,
        }));
      },
    } as DashboardState)
);
