import { emailRegex, newPasswordRegex } from "./regex";

export type ValidationRule = (value: any) => true | string;

export const emailRules: ValidationRule[] = [
  (value: any) => !!value || "err_msg_email_address_empty",
  (value: any) =>
    (!!value && emailRegex.test(value)) ||
    "err_msg_email_address_invalid_format",
];

export const passwordRules: ValidationRule[] = [
  (value: any) => !!value || "err_msg_password_empty",
];

export const newPasswordRules: ValidationRule[] = [
  (value: any) => !!value || "err_msg_new_password_empty",
  (value: any) =>
    (!!value && newPasswordRegex.test(value)) ||
    "err_msg_new_password_invalid_format",
];

export const validateRules = (
  value: any,
  rules: ValidationRule[]
): true | string => {
  for (const rule of rules) {
    const res = rule(value);
    if (res !== true) {
      return res;
    }
  }
  return true;
};
