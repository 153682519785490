import { create } from "zustand";
import {
  UpdateOrder,
  Order,
  OrdersQuery,
  CreateOrder,
  ListOrders,
  OrdersState,
  CancelValidteOutput,
} from "../types/order";
import { useFetcher } from "./fetcher";
import { Paging } from "../types/paging";
import { sortQueryFromOrderByRecord } from "../utils/query";
import { ListOrderEvents, OrderEvent } from "../types/order_event";

export const useOrdersStore = create<OrdersState>(
  (set, get) =>
    ({
      items: [],
      total: null,
      query: null,
      limit: 20,
      search: async (query?: OrdersQuery, fetch?: boolean) => {
        set(() => ({ query }));
        if (fetch !== false) {
          await get().fetch(true);
        }
      },
      export: async (query?: OrdersQuery): Promise<void> => {
        const sort = sortQueryFromOrderByRecord(query?.order_by);
        const params: any = query
          ? {
              ...query,
              order_by: undefined,
              sort,
              offset: 0,
            }
          : { offset: 0 };
        await useFetcher.getState().omsDsFetcher.get("orders", {
          headers: {
            Accept: "text/csv",
          },
          params,
          paramsSerializer: {
            indexes: null,
          },
        });
      },
      fetch: async (reset: boolean): Promise<Paging<Order>> => {
        try {
          const { items, limit, query } = get();
          const sort = sortQueryFromOrderByRecord(query?.order_by);
          const params: any = query
            ? {
                ...query,
                order_by: undefined,
                sort,
                limit,
                offset: reset ? 0 : items.length,
              }
            : { limit, offset: reset ? 0 : items.length };
          const res = await useFetcher
            .getState()
            .omsDsFetcher.get<Paging<Order>>("orders", {
              params,
              paramsSerializer: {
                indexes: null,
              },
            });

          set(() => ({
            items: [...(reset ? [] : items), ...(res?.data?.items ?? [])],
            total: res?.data?.total ?? null,
          }));
          return res?.data ?? null;
        } catch (error) {
          throw error;
        }
      },
      list: async (input?: ListOrders): Promise<Paging<Order>> => {
        try {
          const { limit, offset, order_by, ...query } = input
            ? input
            : { limit: 20, offset: 0, order_by: undefined };
          const sortQuery = sortQueryFromOrderByRecord(order_by);
          const params: any = query
            ? {
                ...query,
                order_by: undefined,
                limit: limit ? limit : 20,
                offset: offset ? offset : 0,
                sort: sortQuery,
              }
            : {
                limit: limit ? limit : 20,
                offset: offset ? offset : 0,
                sort: sortQuery,
              };
          const res = await useFetcher
            .getState()
            .omsDsFetcher.get<Paging<Order>>("orders", {
              params,
              paramsSerializer: {
                indexes: null,
              },
            });
          return res?.data ?? null;
        } catch (error) {
          throw error;
        }
      },
      get: async (id: number): Promise<Order | null> => {
        try {
          const res = await useFetcher
            .getState()
            .omsDsFetcher.get<Order>(`orders/${id}`);
          return res?.data ?? null;
        } catch (error) {
          throw error;
        }
      },
      create: async (input: CreateOrder): Promise<Order | null> => {
        try {
          const res = await useFetcher
            .getState()
            .omsFetcher.post<Order>(`orders`, input);
          get().clear();
          return res?.data ?? null;
        } catch (error) {
          throw error;
        }
      },
      update: async (id: number, input: UpdateOrder): Promise<Order | null> => {
        try {
          const res = await useFetcher
            .getState()
            .omsFetcher.patch<Order>(`orders/${id}`, input);
          if (res.data) {
            const items = [...get().items];
            const idx = items.findIndex((value) => {
              return value.id === id;
            });
            if (idx >= 0) {
              items[idx] = { ...res.data };
              set(() => ({ items: items }));
            }
          }
          return res.data ?? null;
        } catch (error) {
          throw error;
        }
      },
      delete: async (id: number): Promise<void> => {
        try {
          const res = await useFetcher
            .getState()
            .omsFetcher.delete(`orders/${id}`);
          get().clear();
        } catch (error) {
          throw error;
        }
      },
      clear: () => {
        set(() => ({ items: [], total: null, query: null }));
      },
      listEvents: async (
        id: number,
        input?: ListOrderEvents
      ): Promise<Paging<OrderEvent>> => {
        try {
          const { limit, offset, order_by, ...query } = input
            ? input
            : { limit: 20, offset: 0, order_by: undefined };
          const sortQuery = sortQueryFromOrderByRecord(order_by);
          const params: any = query
            ? {
                ...query,
                limit: limit ? limit : 20,
                offset: offset ? offset : 0,
                sort: sortQuery,
              }
            : {
                limit: limit ? limit : 20,
                offset: offset ? offset : 0,
                sort: sortQuery,
              };
          const res = await useFetcher
            .getState()
            .omsDsFetcher.get<Paging<OrderEvent>>(`orders/${id}/events`, {
              params,
              paramsSerializer: {
                indexes: null,
              },
            });
          return res?.data ?? null;
        } catch (error) {
          throw error;
        }
      },
      createSalesOrder: async (id: number): Promise<void> => {
        try {
          await useFetcher
            .getState()
            .omsDsFetcher.post(`orders/${id}/sales-order`);
        } catch (error) {
          throw error;
        }
      },
      createPackingOrder: async (id: number): Promise<void> => {
        try {
          await useFetcher
            .getState()
            .omsDsFetcher.post(`orders/${id}/pick-pack`);
        } catch (error) {
          throw error;
        }
      },
      createDelivery: async (id: number, boxQty: number): Promise<void> => {
        try {
          await useFetcher
            .getState()
            .omsDsFetcher.post(`orders/${id}/delivery`, {
              box_qty: boxQty,
            });
        } catch (error) {
          throw error;
        }
      },
      cancelOrder: async (id: number, reasons?: string): Promise<void> => {
        try {
          await useFetcher.getState().omsDsFetcher.post(`orders/${id}/cancel`, {
            reasons,
          });
        } catch (error) {
          throw error;
        }
      },
      createShipmentLabel: async (id: number): Promise<void> => {
        // Request to marketplace for a new shipment label
        // Marketplace:
        //   - shopee
        // Note: For lazada, we just download it. No need to send a request to create the shipment label.
        try {
          await useFetcher
            .getState()
            .omsDsFetcher.post(`orders/${id}/shipment-label`);
        } catch (error) {
          throw error;
        }
      },
      pullShipmentLabel: async (id: number): Promise<void> => {
        // Pull shipment label from marketplace to OMS backend's S3
        // Marketplace:
        //   - shopee
        //   - lazada
        try {
          await useFetcher
            .getState()
            .omsDsFetcher.post(`orders/${id}/pull-shipment-label`);
        } catch (error) {
          throw error;
        }
      },
      readyToShip: async (id: number): Promise<void> => {
        // Notify the marketplace that the order is ready to ship
        // Marketplace:
        //   - lazada
        try {
          await useFetcher
            .getState()
            .omsDsFetcher.post(`orders/${id}/ready-to-ship`);
        } catch (error) {
          throw error;
        }
      },
      pullUpdate: async (id: number): Promise<void> => {
        // Pull update the order information
        // Marketplace:
        //   - shopee
        //   - lazada
        try {
          await useFetcher
            .getState()
            .omsDsFetcher.post(`orders/${id}/pull-update`);
        } catch (error) {
          throw error;
        }
      },
      returnOrder: async (id: number): Promise<void> => {
        try {
          await useFetcher.getState().omsDsFetcher.post(`orders/${id}/return`);
        } catch (error) {
          throw error;
        }
      },
      cancelValidate: async (id: number): Promise<CancelValidteOutput> => {
        try {
          const res = await useFetcher
            .getState()
            .omsDsFetcher.post<CancelValidteOutput>(
              `orders/${id}/cancel-validate`
            );
          return res.data;
        } catch (error) {
          throw error;
        }
      },
      responseCancellationRequest: async (
        id: number,
        response: "accept" | "reject"
      ): Promise<void> => {
        try {
          await useFetcher
            .getState()
            .omsDsFetcher.post(`orders/${id}/response-cancellation-request`, {
              response,
            });
        } catch (error) {
          throw error;
        }
      },
    } as OrdersState)
);
