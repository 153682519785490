import * as React from "react";
import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  Login,
  VerifiedUser,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { emailRules, passwordRules, validateRules } from "../utils/validation";

export interface SignInFormProps {
  onClickLogin: (username: string, password: string) => Promise<any>;
  onClickContinueWithMinor: () => Promise<any>;
  onClickForgot: () => void;
}

const SignInForm = ({
  onClickForgot,
  onClickLogin,
  onClickContinueWithMinor,
}: SignInFormProps) => {
  const [showPassword, setShowPassword] = React.useState(false);
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [loadingLoginWithMinor, setLoadingLoginWithMinor] =
    React.useState(false);
  const [validationResults, setValidationResults] = React.useState({} as any);
  const {t} = useTranslation("translation");
  
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const handleClickForgotPassword = () => onClickForgot();
  const handleClickLogin = async () => {
    if (validateInputs()) {
      try {
        setLoading(true);
        await onClickLogin(username, password);
      } catch (err) {
      } finally {
        setLoading(false);
      }
    }
  };
  const handleClickContinueWithMinor = async () => {
    try {
      setLoadingLoginWithMinor(true);
      await onClickContinueWithMinor();
    } catch (err) {
    } finally {
      setLoadingLoginWithMinor(false);
    }
  };
  const handleChangeUsername = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setUsername(event.target.value);
  };
  const handleChangePassword = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPassword(event.target.value);
  };

  const handleBlurUsername = () => {
    const res = validateRules(username, emailRules);
    setValidationResults({
      ...validationResults,
      username: res === true ? "" : t(res),
    });
  };

  const handleBlurPassword = () => {
    const res = validateRules(password, passwordRules);
    setValidationResults({
      ...validationResults,
      password: res === true ? "" : t(res),
    });
  };

  const validateInputs = (): boolean => {
    const usernameResult = validateRules(username, emailRules);
    const passwordResult = validateRules(password, passwordRules);
    const results = {
      username: usernameResult === true ? "" : t(usernameResult),
      password: passwordResult === true ? "" : t(passwordResult),
    } as any;
    setValidationResults(results);
    for (const value of Object.values(results)) {
      if (value) {
        return false;
      }
    }
    return true;
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          label={`${t("email_address")}*`}
          variant="outlined"
          type="email"
          value={username}
          onChange={handleChangeUsername}
          onBlur={handleBlurUsername}
          error={!!validationResults.username}
          helperText={validationResults.username}
          sx={{ width: "100%" }}
        ></TextField>
      </Grid>
      <Grid item xs={12}>
        <TextField
          label={`${t("password")}*`}
          variant="outlined"
          type={showPassword ? "text" : "password"}
          sx={{ width: "100%" }}
          value={password}
          onChange={handleChangePassword}
          onBlur={handleBlurPassword}
          error={!!validationResults.password}
          helperText={validationResults.password}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        ></TextField>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button variant="text" onClick={handleClickForgotPassword}>
          {t("forgot_password")}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          startIcon={
            loading ? (
              <CircularProgress
                size={20}
                sx={{ color: "primary.contrastText" }}
              />
            ) : (
              <Login />
            )
          }
          sx={{ width: "100%" }}
          onClick={handleClickLogin}
        >
          {t("login")}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Divider></Divider>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          startIcon={
            loadingLoginWithMinor ? (
              <CircularProgress
                size={20}
                sx={{ color: "primary.contrastText" }}
              />
            ) : (
              <VerifiedUser />
            )
          }
          sx={{ width: "100%", bgcolor: "primary.800" }}
          onClick={handleClickContinueWithMinor}
        >
          {t("continue_with_minor")}
        </Button>
      </Grid>
    </Grid>
  );
};

export default SignInForm;
