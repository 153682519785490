
export const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const phoneRegex = /^(0|\+[1-9])\d{1,14}$/

export const integerRegex = /^-?(0|[1-9]\d*)$/
export const positiveIntegerRegex = /^(0|[1-9]\d*)$/

export const fractionRegex = /^-?(0|[1-9]\d*)(\.\d+)?$/
export const positiveFractionRegex = /^(0|[1-9]\d*)(\.\d+)?$/
export const negativeFractionRegex = /^(0|[1-9]\d*)?(\.\d+)?(?<=\d)$/

export const noWhitespaceRegex = /^[a-zA-Z0-9_]*$/
export const snakeCaseRegex = /^[a-z][a-z0-9_]*$/

// Minimum eight characters, at least one letter and one number
// export const newPasswordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/

// Minimum eight characters, at least one letter, one number and one special character
export const newPasswordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/

// Minimum eight characters, at least one uppercase letter, one lowercase letter and one number
// export const newPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/

// Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character
// export const newPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/

// Minimum eight and maximum 20 characters, at least one uppercase letter, one lowercase letter, one number and one special character
// export const newPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/