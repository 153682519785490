import { create } from "zustand";
import {
  UpdateRole,
  Role,
  RolesQuery,
  RolesState,
  CreateRole,
  RolePolicy,
} from "../types/role";
import { useFetcher } from "./fetcher";
import { Paging } from "../types/paging";
import { sortQueryFromOrderByRecord } from "../utils/query";

export const useRolesStore = create<RolesState>(
  (set, get) =>
    ({
      all: null,
      items: [],
      total: null,
      query: null,
      limit: 20,
      fetchingAll: false,
      search: async (query?: RolesQuery, fetch?: boolean) => {
        set(() => ({ query }));
        if (fetch !== false) {
          await get().fetch(true);
        }
      },
      fetch: async (reset: boolean): Promise<Paging<Role>> => {
        try {
          const { items, limit, query } = get();
          const sort = sortQueryFromOrderByRecord(query?.order_by)
          const params: any = query
            ? { ...query, order_by: undefined, sort, limit, offset: reset ? 0 : items.length }
            : { limit, offset: reset ? 0 : items.length };
          const res = await useFetcher
            .getState()
            .omsFetcher.get<Paging<Role>>("roles", {
              params,
              paramsSerializer: {
                indexes: null,
              },
            });

          set(() => ({
            items: [...(reset ? [] : items), ...(res?.data?.items ?? [])],
            total: res?.data?.total ?? null,
          }));
          return res?.data ?? null;
        } catch (error) {
          throw error;
        }
      },
      get: async (id: string): Promise<Role | null> => {
        try {
          const res = await useFetcher
            .getState()
            .omsFetcher.get<Role>(`roles/${id}`);
          return res?.data ?? null;
        } catch (error) {
          throw error;
        }
      },
      create: async (input: CreateRole): Promise<Role | null> => {
        try {
          const res = await useFetcher
            .getState()
            .omsFetcher.post<Role>(`roles`, input);
          get().clear();
          return res?.data ?? null;
        } catch (error) {
          throw error;
        }
      },
      update: async (id: string, input: UpdateRole): Promise<Role | null> => {
        try {
          const res = await useFetcher
            .getState()
            .omsFetcher.patch<Role>(`roles/${id}`, input);
          if (res.data) {
            const items = [...get().items];
            const idx = items.findIndex((value) => {
              return value.id === id;
            });
            if (idx >= 0) {
              items[idx] = { ...res.data };
              set(() => ({ items }));
            }
          }
          return res.data ?? null;
        } catch (error) {
          throw error;
        }
      },
      delete: async (id: string): Promise<void> => {
        try {
          const res = await useFetcher
            .getState()
            .omsFetcher.delete(`roles/${id}`);
          get().clear();
        } catch (error) {
          throw error;
        }
      },
      getPolicy: async (id: string): Promise<RolePolicy> => {
        try {
          const res = await useFetcher
            .getState()
            .omsFetcher.get<RolePolicy>(`roles/${id}/policy`);
          return res?.data ?? null;
        } catch (error) {
          throw error;
        }
      },
      setPolicy: async (id: string, input: RolePolicy): Promise<void> => {
        try {
          await useFetcher
            .getState()
            .omsFetcher.put<void>(`roles/${id}/policy`, input);
        } catch (error) {
          throw error;
        }
      },
      clear: () => {
        set(() => ({ items: [], total: null, query: null }));
      },
      fetchAll: async (reset: boolean): Promise<Role[]> => {
        if (!reset && get().all !== null) {
          return get().all!;
        }
        try {
          set(() => ({ fetchingAll: true }));
          const all = [] as Role[];
          while (true) {
            const res = await useFetcher
              .getState()
              .omsFetcher.get<Paging<Role>>("roles", {
                params: {
                  limit: 100,
                  offset: all.length,
                  sort: "name",
                },
              });
            if (res?.data?.items && res.data.items.length > 0) {
              all.push(...res.data.items);
              if (all.length >= res.data.total) {
                break;
              }
            } else {
              break;
            }
          }
          set(() => ({ all }));
          return all;
        } catch (err) {
          throw err;
        } finally {
          set(() => ({ fetchingAll: false }));
        }
      },
    } as RolesState)
);
