import * as React from "react";
import {
  Box,
  Button,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

interface ActionItemProps {
  title?: string;
  value?: string;
  icon?: React.ReactElement;
  color?: string;
  disabled?: boolean;
  divider?: boolean;
}

interface ActionListButtonProps {
  title?: React.ReactNode;
  variant?: "text" | "contained" | "outlined";
  icon?: React.ReactElement;
  color?: string;
  actions?: ActionItemProps[];
  onAction?: (value: string) => void | Promise<void>;
}

const ActionListButton = ({
  title,
  variant,
  icon,
  color,
  actions,
  onAction,
  ...props
}: ActionListButtonProps & Record<string, any>) => {
  const { t } = useTranslation();
  const [anchorMenuEl, setAnchorMenuEl] = React.useState<null | HTMLElement>(
    null
  );
  const openMenu = Boolean(anchorMenuEl);
  const handleClickButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorMenuEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorMenuEl(null);
  };
  const handleItemClick = (item: ActionItemProps) => (event: any) => {
    if (onAction) {
      onAction(item.value ?? "");
    }
    handleCloseMenu();
  };

  const menuItems = actions?.map((action: ActionItemProps, idx: number) =>
    action.divider ? (
      <Divider key={`action-item-${idx}`} />
    ) : (
      <MenuItem
        onClick={handleItemClick(action)}
        key={`action-item-${idx}`}
        disabled={action.disabled ?? false}
      >
        {action.icon && <ListItemIcon>{action.icon}</ListItemIcon>}
        <ListItemText>{action.title ?? action.value ?? ""}</ListItemText>
      </MenuItem>
    )
  );

  return (
    <Box sx={{ flexGrow: 0 }}>
      {!!title ? (
        <Button
          variant={variant ?? "text"}
          startIcon={icon}
          color={color as any}
          endIcon={openMenu ? <ExpandLess /> : <ExpandMore />}
          onClick={handleClickButton}
          {...props}
        >
          {title}
        </Button>
      ) : (
        <IconButton onClick={handleClickButton}>{icon}</IconButton>
      )}

      {actions && actions.length > 0 && (
        <Menu
          anchorEl={anchorMenuEl}
          open={openMenu}
          onClose={handleCloseMenu}
          MenuListProps={{}}
        >
          {menuItems}
        </Menu>
      )}
    </Box>
  );
};

export default ActionListButton;
