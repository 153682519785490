import * as React from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  newPasswordRules,
  passwordRules,
  validateRules,
} from "../utils/validation";

export interface ChangePasswordFormProps {
  hideCurrentPassword: boolean;
  onChangePassword: (
    newPassword: string,
    currentPassword: string
  ) => Promise<void>;
  onCancel: () => void;
}

const ChangePasswordForm = ({
  hideCurrentPassword,
  onChangePassword,
  onCancel,
}: ChangePasswordFormProps) => {
  const [showCurrentPassword, setShowCurrentPassword] = React.useState(false);
  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] =
    React.useState(false);
  const [currentPassword, setCurrentPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [confirmNewPassword, setConfirmNewPassword] = React.useState("");
  const [validationResults, setValidationResults] = React.useState({} as any);
  const { t } = useTranslation("translation");

  const handleClickShowCurrentPassword = () =>
    setShowCurrentPassword((show) => !show);
  const handleMouseDownCurrentPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);
  const handleMouseDownNewPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const handleClickShowConfirmNewPassword = () =>
    setShowConfirmNewPassword((show) => !show);
  const handleMouseDownConfirmNewPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const handleClickChangeButton = async () => {
    if (validateInputs()) {
      try {
        setLoading(true);
        await onChangePassword(newPassword, currentPassword);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    }
  };
  const handleClickCancelButton = () => onCancel();

  const handleChangeCurrentPassword = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setCurrentPassword(event.target.value);
  };
  const handleChangeNewPassword = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setNewPassword(event.target.value);
  };

  const handleChangeConfirmNewPassword = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setConfirmNewPassword(event.target.value);
  };

  const handleBlurCurrentPassword = () => {
    const res = hideCurrentPassword
      ? true
      : validateRules(currentPassword, passwordRules);
    setValidationResults({
      ...validationResults,
      current_password: res === true ? "" : t(res),
    });
  };

  const handleBlurNewPassword = () => {
    const res = validateRules(newPassword, newPasswordRules);
    setValidationResults({
      ...validationResults,
      new_password: res === true ? "" : t(res),
    });
  };

  const handleBlurConfirmNewPassword = () => {
    setValidationResults({
      ...validationResults,
      confirm_new_password:
        newPassword === confirmNewPassword
          ? ""
          : t("err_msg_new_password_confirmation_mismatch"),
    });
  };

  const validateInputs = (): boolean => {
    const currentPasswordResult = hideCurrentPassword
      ? true
      : validateRules(currentPassword, passwordRules);
    const newPasswordResult = validateRules(newPassword, newPasswordRules);
    const confirmNewPasswordResult =
      newPassword === confirmNewPassword ||
      "err_msg_new_password_confirmation_mismatch";
    const results = {
      current_password:
        currentPasswordResult === true ? "" : t(currentPasswordResult),
      new_password: newPasswordResult === true ? "" : t(newPasswordResult),
      confirm_new_password:
        confirmNewPasswordResult === true ? "" : t(confirmNewPasswordResult),
    } as any;
    setValidationResults(results);
    for (const value of Object.values(results)) {
      if (value) {
        return false;
      }
    }
    return true;
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="body2">
          {t("hint_change_password")}
        </Typography>
      </Grid>
      {!hideCurrentPassword && (
        <Grid item xs={12}>
          <TextField
            label={`${t("current_password")}*`}
            variant="outlined"
            type={showCurrentPassword ? "text" : "password"}
            sx={{ width: "100%" }}
            value={currentPassword}
            onChange={handleChangeCurrentPassword}
            onBlur={handleBlurCurrentPassword}
            error={!!validationResults.current_password}
            helperText={validationResults.current_password}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowCurrentPassword}
                    onMouseDown={handleMouseDownCurrentPassword}
                    edge="end"
                  >
                    {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          ></TextField>
        </Grid>
      )}
      <Grid item xs={12}>
        <TextField
          label={`${t("new_password")}*`}
          variant="outlined"
          type={showNewPassword ? "text" : "password"}
          sx={{ width: "100%" }}
          value={newPassword}
          onChange={handleChangeNewPassword}
          onBlur={handleBlurNewPassword}
          error={!!validationResults.new_password}
          helperText={validationResults.new_password}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowNewPassword}
                  onMouseDown={handleMouseDownNewPassword}
                  edge="end"
                >
                  {showNewPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        ></TextField>
      </Grid>
      <Grid item xs={12}>
        <TextField
          label={`${t("confirm_new_password")}*`}
          variant="outlined"
          type={showConfirmNewPassword ? "text" : "password"}
          sx={{ width: "100%" }}
          value={confirmNewPassword}
          onChange={handleChangeConfirmNewPassword}
          onBlur={handleBlurConfirmNewPassword}
          error={!!validationResults.confirm_new_password}
          helperText={validationResults.confirm_new_password}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowConfirmNewPassword}
                  onMouseDown={handleMouseDownConfirmNewPassword}
                  edge="end"
                >
                  {showConfirmNewPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        ></TextField>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ height: "24px", width: "100%" }}></Box>
      </Grid>
      <Grid item xs={6}>
        <Button
          variant="outlined"
          sx={{ width: "100%" }}
          onClick={handleClickCancelButton}
        >
          {t("cancel")}
        </Button>
      </Grid>
      <Grid item xs={6}>
        <Button
          variant="contained"
          sx={{ width: "100%" }}
          onClick={handleClickChangeButton}
          startIcon={
            loading && (
              <CircularProgress
                size={20}
                sx={{ color: "primary.contrastText" }}
              />
            )
          }
          disabled={
            (!currentPassword && !hideCurrentPassword) ||
            !newPassword ||
            newPassword !== confirmNewPassword
          }
        >
          {t("change")}
        </Button>
      </Grid>
    </Grid>
  );
};

export default ChangePasswordForm;
