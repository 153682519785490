// import * as React from "react";
import i18n, { InitOptions } from "i18next"
import detector from "i18next-browser-languagedetector";
import backend from "i18next-xhr-backend"
import { initReactI18next } from "react-i18next";
import en from '../locales/en/translation.json';
import th from '../locales/th/translation.json';

i18n
  .use(backend)
  .use(detector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    debug: true,
    resources : {
      en: {
          translation : en
      },
      th: {
          translation : th
      }
    },

    saveMissing: true,
    keySeparator: false, 

    // have a common namespace used around the full app
    ns: ["translation"],
    defaultNS: "translation",

    interpolation: {
      escapeValue: false, // not needed for react!!
    },
    
    react: {
      wait: true,
      useSuspense: false
    },
  } as InitOptions);

export default i18n;