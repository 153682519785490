import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import { navigate } from "gatsby";
import { useTranslation } from "react-i18next";
import { Chip, useTheme } from "@mui/material";
import { AccountCircle, Construction, Logout } from "@mui/icons-material";
import { useAuthStore } from "../stores/auth";
import { useAppStore } from "../stores/app";
import ActionListButton from "./ActionListButton";
import { useProfileStore } from "../stores/profile";
import UserAvatar from "./UserAvatar";
import { Domain } from "../types/domain";
import { useMetadataStore } from "../stores/metadata";

const AppNavBar = () => {
  const theme = useTheme();
  const { t } = useTranslation("translation");
  const auth = useAuthStore();
  const app = useAppStore();
  const profileStore = useProfileStore();
  const metadataStore = useMetadataStore();
  const [underMaintenance, setUnderMaintenance] = React.useState(false);

  React.useEffect(() => {
    // Ensure load system maintenance metadata
    fetchSystemMaintenanceData();
  }, [metadataStore.systemMaintenance]);

  const fetchSystemMaintenanceData = async () => {
    const data = metadataStore.systemMaintenance;
    if (data?.enabled) {
      setUnderMaintenance(true);
    } else {
      setUnderMaintenance(false);
    }
  };

  const handleToggleSideMenu = (event: React.MouseEvent<HTMLElement>) => {
    const visible = app.sideMenuVisible;
    app.setSideMenuVisible(!visible);
  };

  const handleClickLogo = () => {
    navigate("/");
  };

  const handleActions = (value: string) => {
    switch (value) {
      case "profile":
        handleClickProfile();
        break;
      case "logout":
        handleClickLogout();
        break;
    }
  };

  // const handleChangeCurrentWorkspace = async (
  //   event: React.SyntheticEvent,
  //   value: Workspace,
  // ) => {
  //   await profileStore.setCurrentWorkspace(value.id);
  // };

  const handleChangeCurrentWorkspace = async (value: string) => {
    await profileStore.setCurrentWorkspace(value);
  };

  const handleClickProfile = () => {
    // Navigate to profile page
    navigate("/profile");
  };

  const handleClickLogout = async () => {
    await auth.signOut();
  };

  const domainsList = profileStore?.profile?.domains?.map((v: Domain) => {
    return {
      title: v.name,
      value: v.id,
    };
  });

  const profileMenuActions = [
    {
      title: t("profile"),
      value: "profile",
      icon: <AccountCircle fontSize="small" />,
    },
    {
      title: t("logout"),
      value: "logout",
      icon: <Logout fontSize="small" />,
    },
  ];

  return (
    <AppBar position="fixed" sx={{ zIndex: theme.zIndex.drawer + 1 }}>
      <Toolbar>
        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            width: "44px",
            height: "44px",
          }}
        >
          <img
            src="/images/logo-white.png"
            style={{ width: "44px", height: "44px" }}
          />
        </Box>

        <Box
          sx={{
            flexGrow: 1,
            display: { xs: "flex", md: "none" },
            flexDirection: "row",
          }}
        >
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleToggleSideMenu}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
          <Box
            sx={{
              display: { xs: "flex", md: "none" },
              width: "44px",
              height: "44px",
              ml: 2,
            }}
          >
            <img
              src="/images/logo-white.png"
              style={{ width: "44px", height: "44px" }}
            />
          </Box>
        </Box>

        {underMaintenance && (
          <Chip
            color="warning"
            icon={<Construction />}
            label={t("under_maintenance")}
            sx={{ mx: 2 }}
          />
        )}
        <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}></Box>

        <Box sx={{ flexGrow: 0 }}>
          <Box
            sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          >
            {profileStore.currentDomain && (
              <ActionListButton
                title={
                  <>
                    <Typography
                      variant="caption"
                      sx={{
                        display: { xs: "block", md: "none" },
                        textTransform: "capitalize",
                      }}
                    >
                      {profileStore.currentDomain.name}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        display: { xs: "none", md: "block" },
                        textTransform: "capitalize",
                      }}
                    >
                      {profileStore.currentDomain.name}
                    </Typography>
                  </>
                }
                variant="text"
                actions={domainsList}
                onAction={handleChangeCurrentWorkspace}
                color="surface"
              ></ActionListButton>
              // <Autocomplete
              //   value={profileStore.currentWorkspace}
              //   disableClearable
              //   options={profileStore.profile?.workspaces ?? []}
              //   getOptionLabel={(v) => v.name}
              //   sx={{ width: "120px", height: "32px", mr: 2 }}
              //   onChange={handleChangeCurrentWorkspace}
              //   renderInput={(params) => (
              //     <TextField {...params} variant="standard" color={"surface" as any} />
              //   )}
              // />
            )}
            <ActionListButton
              onAction={handleActions}
              icon={<UserAvatar user={profileStore.profile}></UserAvatar>}
              actions={profileMenuActions}
            ></ActionListButton>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default AppNavBar;
