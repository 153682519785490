import { create } from "zustand";
import { AuthState } from "../types/auth";
import { Amplify } from "aws-amplify";
import * as Auth from "aws-amplify/auth";
import { Hub } from "aws-amplify/utils";

Amplify.configure({
  Auth: {
    Cognito: {
      identityPoolId: "",
      userPoolId: process.env.GATSBY_AWS_COGNITO_USERPOOL_ID ?? "",
      userPoolClientId: process.env.GATSBY_AWS_COGNITO_CLIENT_ID ?? "",
      loginWith: {
        oauth: {
          domain:
            process.env.GATSBY_AWS_COGNITO_OAUTH_DOMAIN ?? "",
          scopes: ["email", "openid", "profile"],
          redirectSignIn: [
            process.env.GATSBY_AWS_COGNITO_REDIRECT_SIGNIN ?? ""
          ],
          redirectSignOut: [
            process.env.GATSBY_AWS_COGNITO_REDIRECT_SIGNOUT ?? ""
          ],
          responseType: "code",
          providers: [{ custom: "MINOR" }],
        },
      },
    },
  },
});

export const useAuthStore = create<AuthState>(
  (set, get) =>
    ({
      session: null,

      // Use idToken as a token
      token: (): string => get().session?.tokens?.idToken?.toString() ?? "",

      // Use accessToken as a token
      // token: (): string => get().session?.tokens?.accessToken?.toString() ?? "",

      isSignedIn: (): boolean => {
        return !!get().token();
      },
      fetchSession: async (): Promise<void> => {
        const session = await Auth.fetchAuthSession();
        console.log("SESSION", session);
        if (session) {
          set(() => ({ session }));
        } else {
          set(() => ({ session: null }));
        }
      },
      signIn: async (
        username: string,
        password: string
      ): Promise<Auth.SignInOutput> => {
        const output = await Auth.signIn({ username, password });
        if (output.isSignedIn && output.nextStep.signInStep === "DONE") {
          await get().fetchSession();
        } else {
          set(() => ({ session: null }));
        }
        return output;
      },
      signInWithMinor: async (): Promise<void> => {
        await Auth.signInWithRedirect({
          provider: {
            custom: "MINOR",
          },
        });
      },
      signOut: async (): Promise<void> => {
        await Auth.signOut();
        set(() => ({ session: null }));
      },
      resetPassword: async (
        username: string
      ): Promise<Auth.ResetPasswordOutput> => {
        return await Auth.resetPassword({
          username,
        });
      },
      confirmResetPassword: async (
        username: string,
        confirmationCode: string,
        newPassword: string
      ): Promise<void> => {
        await Auth.confirmResetPassword({
          username,
          confirmationCode,
          newPassword,
        });
      },
      confirmSignInWithNewPassword: async (
        newPassword: string
      ): Promise<Auth.ConfirmSignInOutput> => {
        return await Auth.confirmSignIn({
          challengeResponse: newPassword,
        });
      },
    } as AuthState)
);

Hub.listen("auth", ({ payload }: any) => {
  switch (payload.event) {
    case "signInWithRedirect":
      useAuthStore.getState().fetchSession();
      break;
    case "signInWithRedirect_failure":
      // handle sign in failure
      break;
    case "customOAuthState":
      const state = payload.data; // this will be customState provided on signInWithRedirect function
      console.log(state);
      break;
  }
});
