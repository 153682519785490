import { create } from "zustand";
import { AppState } from "../types/app";

export const useAppStore = create<AppState>(
  (set) =>
    ({
      sideMenuExpanded: true,
      sideMenuVisible: false,
      maintenanceRedirect: false,
      setSideMenuExpanded: (value: boolean) => {
        set(() => ({ sideMenuExpanded: value }));
      },
      setSideMenuVisible: (value: boolean) => {
        set(() => ({ sideMenuVisible: value }));
      },
      setMaintenanceRedirect: (value: boolean) => {
        set(() => ({ maintenanceRedirect: value }));
      },
    } as AppState)
);
