export const sortQueryFromOrderByRecord = (
  orderBy?: Record<string, "asc" | "desc">
): string[] | undefined => {
  if (!orderBy) return undefined;
  const sortQuery = [] as string[];
  for (const [key, value] of Object.entries(orderBy)) {
    if (value === "desc") {
      sortQuery.push(`-${key}`);
    } else {
      sortQuery.push(key);
    }
  }
  return sortQuery.length === 0 ? undefined : sortQuery;
};
