import React, { useEffect } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { SnackbarProvider } from "notistack";
import Layout from "../components/Layout";
import Authenticator from "../components/Authenticator";
import { useAuthStore } from "../stores/auth";
import "../components/i18n";
import { useProfileStore } from "../stores/profile";
import { useMetadataStore } from "../stores/metadata";
import { useAppStore as useOmsAppStore } from "../stores/app";
import MaintenanceRedirector from "../components/MaintenanceRedirector";

export const AppContext = React.createContext({});

export const primary = {
  "50": "#f2f6fd",
  "100": "#e5ecf9",
  "200": "#c5d7f2",
  "300": "#92b5e7",
  "400": "#578ed9",
  "500": "#3270c5",
  "600": "#2358aa",
  "700": "#1d4587",
  "800": "#1c3d70",
  "900": "#1c345e",
  "950": "#13223e",
  light: "#3270c5",
  main: "#2358aa",
  dark: "#1c345e",
  contrastText: "#ffffff",
};

export const surface = {
  "50": "#ffffff",
  "100": "#f8f8f8",
  "200": "#f0f0f0",
  "300": "#dcdcdc",
  "400": "#bdbdbd",
  "500": "#989898",
  "600": "#7c7c7c",
  "700": "#656565",
  "800": "#525252",
  "900": "#464646",
  "950": "#3d3d3d",
  // "950": "#292929",
  light: "#ffffff",
  main: "#f8f8f8",
  dark: "#bdbdbd",
  contrastText: "#525252",
};

const theme = createTheme({
  palette: {
    primary,
    surface,
  } as any,
});

// eslint-disable-next-line react/display-name,react/prop-types
const AppContextProvider = ({ children }: any) => {
  const auth = useAuthStore();
  const profileStore = useProfileStore();
  const metadataStore = useMetadataStore();
  const [isSignedIn, setIsSignedIn] = React.useState(null as boolean | null);

  useEffect(() => {
    auth.fetchSession();
  }, []);

  useEffect(() => {
    if (auth.token()) {
      profileStore.fetch();
      metadataStore.get("system_maintenance");
      setIsSignedIn(true);
    } else {
      profileStore.clear();
      metadataStore.clear();
      setIsSignedIn(false);
    }
  }, [auth.session]);

  return (
    <AppContext.Provider value={{}}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={3}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            {isSignedIn ? (
              <MaintenanceRedirector>
                <Layout>{children}</Layout>
              </MaintenanceRedirector>
            ) : isSignedIn === false ? (
              <Authenticator />
            ) : (
              <div></div>
            )}
          </LocalizationProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </AppContext.Provider>
  );
};

export default AppContextProvider;
