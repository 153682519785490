import { create } from "zustand";
import {
  UpdateChannel,
  Channel,
  ChannelsQuery,
  ChannelsState,
  CreateChannel,
  ChannelClientConfig,
  ListChannels,
  ChannelPullOrdersRequest,
  ChannelPullOrdersResponse,
} from "../types/channel";
import { useFetcher } from "./fetcher";
import { Paging } from "../types/paging";
import { sortQueryFromOrderByRecord } from "../utils/query";

export const useChannelsStore = create<ChannelsState>(
  (set, get) =>
    ({
      all: null,
      items: [],
      total: null,
      query: null,
      limit: 20,
      fetchingAll: false,
      search: async (query?: ChannelsQuery, fetch?: boolean) => {
        set(() => ({ query }));
        if (fetch !== false) {
          await get().fetch(true);
        }
      },
      fetch: async (reset: boolean): Promise<Paging<Channel>> => {
        try {
          const { items, limit, query } = get();
          const sort = sortQueryFromOrderByRecord(query?.order_by);
          const params: any = query
            ? {
                ...query,
                order_by: undefined,
                sort,
                limit,
                offset: reset ? 0 : items.length,
              }
            : { limit, offset: reset ? 0 : items.length };
          const res = await useFetcher
            .getState()
            .omsDsFetcher.get<Paging<Channel>>("channels", {
              params,
              paramsSerializer: {
                indexes: null,
              },
            });

          set(() => ({
            items: [...(reset ? [] : items), ...(res?.data?.items ?? [])],
            total: res?.data?.total ?? null,
          }));
          return res?.data ?? null;
        } catch (error) {
          throw error;
        }
      },
      list: async (input?: ListChannels): Promise<Paging<Channel>> => {
        try {
          const { limit, offset, order_by, ...query } = input
            ? input
            : { limit: 20, offset: 0, order_by: undefined };
          const sortQuery = sortQueryFromOrderByRecord(order_by);
          const params: any = query
            ? {
                ...query,
                limit: limit ? limit : 20,
                offset: offset ? offset : 0,
                sort: sortQuery,
              }
            : {
                limit: limit ? limit : 20,
                offset: offset ? offset : 0,
                sort: sortQuery,
              };
          const res = await useFetcher
            .getState()
            .omsDsFetcher.get<Paging<Channel>>("channels", {
              params,
              paramsSerializer: {
                indexes: null,
              },
            });
          return res?.data ?? null;
        } catch (error) {
          throw error;
        }
      },
      get: async (id: number): Promise<Channel | null> => {
        try {
          const res = await useFetcher
            .getState()
            .omsDsFetcher.get<Channel>(`channels/${id}`);
          return res?.data ?? null;
        } catch (error) {
          throw error;
        }
      },
      create: async (input: CreateChannel): Promise<Channel | null> => {
        try {
          const res = await useFetcher
            .getState()
            .omsFetcher.post<Channel>(`channels`, input);
          get().clear();
          return res?.data ?? null;
        } catch (error) {
          throw error;
        }
      },
      update: async (
        id: number,
        input: UpdateChannel
      ): Promise<Channel | null> => {
        try {
          const res = await useFetcher
            .getState()
            .omsFetcher.patch<Channel>(`channels/${id}`, input);
          if (res.data) {
            const items = [...get().items];
            const idx = items.findIndex((value) => {
              return value.id === id;
            });
            if (idx >= 0) {
              items[idx] = { ...res.data };
              set(() => ({ items: items }));
            }
          }
          return res.data ?? null;
        } catch (error) {
          throw error;
        }
      },
      delete: async (id: number): Promise<void> => {
        try {
          const res = await useFetcher
            .getState()
            .omsFetcher.delete(`channels/${id}`);
          get().clear();
        } catch (error) {
          throw error;
        }
      },
      getConfig: async (id: number): Promise<ChannelClientConfig | null> => {
        try {
          const res = await useFetcher
            .getState()
            .omsDsFetcher.get<ChannelClientConfig>(
              `channels/${id}/client-config`
            );
          return res?.data ?? null;
        } catch (error) {
          throw error;
        }
      },
      setConfig: async (
        id: number,
        input: ChannelClientConfig
      ): Promise<void> => {
        try {
          await useFetcher
            .getState()
            .omsFetcher.patch<Channel>(`channels/${id}/client-config`, input);
        } catch (error) {
          throw error;
        }
      },
      clear: () => {
        set(() => ({ items: [], total: null, query: null }));
      },
      fetchAll: async (reset: boolean): Promise<Channel[]> => {
        if (!reset && get().all !== null) {
          return get().all!;
        }
        try {
          set(() => ({ fetchingAll: true }));
          const all = [] as Channel[];
          while (true) {
            const res = await useFetcher
              .getState()
              .omsDsFetcher.get<Paging<Channel>>("channels", {
                params: {
                  limit: 100,
                  offset: all.length,
                  sort: "name",
                },
              });
            if (res?.data?.items && res.data.items.length > 0) {
              all.push(...res.data.items);
              if (all.length >= res.data.total) {
                break;
              }
            } else {
              break;
            }
          }
          set(() => ({ all }));
          return all;
        } catch (err) {
          throw err;
        } finally {
          set(() => ({ fetchingAll: false }));
        }
      },
      pullOrders: async (
        id: number,
        input: ChannelPullOrdersRequest
      ): Promise<ChannelPullOrdersResponse> => {
        try {
          const resp = await useFetcher
            .getState()
            .omsFetcher.post<ChannelPullOrdersResponse>(
              `channels/${id}/pull-orders`,
              input ?? {}
            );
          return resp.data;
        } catch (error) {
          throw error;
        }
      },
    } as ChannelsState)
);
