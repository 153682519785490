import { create } from "zustand";
import {
  UpdateMetadata,
  Metadata,
  MetadataQuery,
  MetadataState,
  CreateMetadata,
  SystemMaintenance,
} from "../types/metadata";
import { useFetcher } from "./fetcher";
import { Paging } from "../types/paging";
import { sortQueryFromOrderByRecord } from "../utils/query";

export const useMetadataStore = create<MetadataState>(
  (set, get) =>
    ({
      items: [],
      total: null,
      query: null,
      limit: 20,
      systemMaintenance: null,
      search: async (query?: MetadataQuery, fetch?: boolean) => {
        set(() => ({ query }));
        if (fetch !== false) {
          await get().fetch(true);
        }
      },
      fetch: async (reset: boolean): Promise<Paging<Metadata>> => {
        try {
          const { items, limit, query } = get();
          const sort = sortQueryFromOrderByRecord(query?.order_by)
          const params: any = query
            ? { ...query, order_by: undefined, sort, limit, offset: reset ? 0 : items.length }
            : { limit, offset: reset ? 0 : items.length };
          const res = await useFetcher
            .getState()
            .omsDsFetcher
            .get<Paging<Metadata>>("metadata", {
              params,
              paramsSerializer: {
                indexes: null,
              },
            });

          set(() => ({
            items: [...(reset ? [] : items), ...(res?.data?.items ?? [])],
            total: res?.data?.total ?? null,
          }));
          return res?.data ?? null;
        } catch (error) {
          throw error;
        }
      },
      get: async (id: string): Promise<Metadata | null> => {
        try {
          const res = await useFetcher
            .getState()
            .omsDsFetcher
            .get<Metadata>(`metadata/${id}`);

          switch (id) {
            case "system_maintenance":
              set(() => ({
                systemMaintenance: res?.data?.data
                  ? (res.data.data as SystemMaintenance)
                  : null,
              }));
              console.log("update system_maintenance");
          }
          return res?.data ?? null;
        } catch (error) {
          throw error;
        }
      },
      create: async (input: CreateMetadata): Promise<Metadata | null> => {
        try {
          const res = await useFetcher
            .getState()
            .omsFetcher
            .post<Metadata>(`metadata`, input);
          get().clear();
          return res.data ?? null;
        } catch (error) {
          throw error;
        }
      },
      update: async (
        id: string,
        input: UpdateMetadata
      ): Promise<Metadata | null> => {
        try {
          const res = await useFetcher
            .getState()
            .omsFetcher
            .patch<Metadata>(`metadata/${id}`, input);
          if (res.data) {
            const items = [...get().items];
            const idx = items.findIndex((value) => {
              return value.id === id;
            });
            if (idx >= 0) {
              items[idx] = { ...res.data };
              set(() => ({ items: items }));
            }
          }
          switch (id) {
            case "system_maintenance":
              set(() => ({
                systemMaintenance: res?.data?.data
                  ? (res.data.data as SystemMaintenance)
                  : null,
              }));
          }
          return res?.data ?? null;
        } catch (error) {
          throw error;
        }
      },
      delete: async (id: string): Promise<void> => {
        try {
          const res = await useFetcher
            .getState()
            .omsFetcher
            .delete(`metadata/${id}`);
          get().clear();
        } catch (error) {
          throw error;
        }
      },
      clear: () => {
        set(() => ({
          items: [],
          total: null,
          query: null,
          systemMaintenance: null,
        }));
      },
    } as MetadataState)
);
