import { create } from "zustand";
import { Profile, ProfilePermissions, ProfileState } from "../types/profile";
import { useFetcher } from "./fetcher";
import { UpdateUser, UserPermission } from "../types/user";
import { Domain } from "../types/domain";
import { useMetadataStore } from "./metadata";
import { useChannelsStore } from "./channels";
import { useRolesStore } from "./roles";
import { useUsersStore } from "./users";
import { useOrdersStore } from "./orders";
import { useDashboardStore } from "./dashboard";

function getCurrentDomainFromProfile(profile?: Profile): Domain | null {
  if (profile?.domains) {
    if (profile.config?.current_workspace) {
      const workspace = profile.domains.find(
        (d: Domain) => d.id === profile.config.current_workspace
      );
      if (workspace) {
        return workspace;
      }
    }

    if (
      profile.domains.findIndex(
        (v: Domain) => v.id === "*" || v.id === "global"
      ) >= 0
    ) {
      return { id: "global", name: "Global" };
    } else if (profile.domains.length > 0) {
      return profile.domains[0];
    }
  }
  return null;
}

export const useProfileStore = create<ProfileState>(
  (set, get) =>
    ({
      profile: null,
      permissions: [],
      currentDomain: null,
      fetch: async (): Promise<Profile> => {
        try {
          const res = await useFetcher
            .getState()
            .omsFetcher.get<Profile>("profile");
          set(() => ({ profile: res?.data ?? null }));
          set(() => ({
            currentDomain: getCurrentDomainFromProfile(res?.data),
          }));
          useFetcher.getState().updateDsFetcher()
          await get().fetchPermissions();
          return res?.data ?? null;
        } catch (error) {
          throw error;
        }
      },
      hasPermission: (resource: string, action: string): boolean => {
        const workspace = get().currentDomain;
        const idx = get().permissions.findIndex((v: UserPermission) => {
          return (
            (!v.domain || v.domain === workspace?.id) &&
            (v.resource === "*" || v.resource === resource) &&
            (v.action === "*" || v.action === action)
          );
        });
        return idx >= 0;
      },
      fetchPermissions: async (): Promise<ProfilePermissions> => {
        try {
          const res = await useFetcher
            .getState()
            .omsDsFetcher.get<ProfilePermissions>(
              "profile/permissions"
            );
          set(() => ({ permissions: res?.data.permissions ?? [] }));
          return res?.data ?? null;
        } catch (error) {
          throw error;
        }
      },
      update: async (input: UpdateUser): Promise<Profile | null> => {
        try {
          const res = await useFetcher
            .getState()
            .omsFetcher.patch<Profile>("profile", input);
          set(() => ({ profile: res?.data ?? null }));
          set(() => ({
            currentDomain: getCurrentDomainFromProfile(res?.data),
          }));
          useFetcher.getState().updateDsFetcher()
          await get().fetchPermissions();

          // Refresh users list
          useUsersStore.getState().clear();

          return res?.data ?? null;
        } catch (error) {
          throw error;
        }
      },
      setCurrentWorkspace: async (workspace: string): Promise<void> => {
        if (workspace === get().currentDomain?.id) {
          return
        }
        useMetadataStore.getState().clear()
        useChannelsStore.getState().clear()
        useRolesStore.getState().clear()
        useUsersStore.getState().clear()
        useOrdersStore.getState().clear()
        useDashboardStore.getState().clear()
        await get().update({
          config: {
            current_workspace: workspace,
          },
        });
      },
      clear: () => {
        set(() => ({ profile: null }));
      },
    } as ProfileState)
);
